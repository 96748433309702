import React, { useState, useContext, useEffect } from "react";
import "./BottomSection.css";
import { progressBarContext } from "../../../Context/ProgressContext";
import { slideHandleContext } from "../../../Context/SlideHandler";
import { formValuesContext } from "../../../Context/ValuesContext";
import Button from "../Button/Button";
import ShowComponent from "../ShowComponent/ShowComponent";
const BottomSection = () => {
  const {
    assignCurrentSlide,
    prevSlide,
    nextSlide,
    navigation,
    setSection,
    currentSlide,
    fieldSelectionError,
    progress,
  } = useContext(slideHandleContext);
  const { submitForm, otpVerified, emailError, formValues, submitInProgress } = useContext(formValuesContext);
  const { phone_number } = formValues
  const navigationOnClick = () => {
    if (!navigation) {
      assignCurrentSlide(nextSlide);
    } else {
      fieldSelectionError();
    }
  };
  const navigateToHome = () => {
    if (prevSlide === "home") {
      setSection("home");
    } else {
      assignCurrentSlide(prevSlide);
    }
  };
  const [submitState, setSubmitState] = useState(true);
  // Email required - Phone numbber not required 
  useEffect(() => {

  const isValidEmail = !emailError;
  const isValidPhoneNumber = phone_number.length === 11;


    if (isValidEmail && isValidPhoneNumber) {
  
      setSubmitState(false);
    } else {
 
      setSubmitState(true);
    }
    if (submitInProgress) {
      setSubmitState(true);
    }
  }, [otpVerified, emailError, phone_number, submitInProgress]);

  return (
    <div
      style={{
        marginBottom: "70px",
      }}
      className="bottom-section-main"
    >
      <div
        style={{
          display:
            nextSlide === "none" || prevSlide === "none" ? "none" : "block",
        }}
        className="slider-prev-next-buttons-main"
      >
        <div className="slider-prev-next-buttons-inner">
          <div className="slider-prev-next-buttons-content width justified">
            <Button
              text="Back"
              id={currentSlide + "Back"}
              onClick={navigateToHome}
              disabled={nextSlide === "NA" || nextSlide === "none"}
              variant="text-only"
              icon="left_blue"
              iconPlacement="left"
            />

            <ShowComponent condition={nextSlide === "formSubmit"}>
              <Button
                id={nextSlide}
                text="Submit"
                onClick={() => submitForm()}
                disabled={submitState}
                variant="filled"
                icon="right_white"
                addDisabled={true}
              />
            </ShowComponent>
            <ShowComponent condition={nextSlide !== "formSubmit"}>
              <Button
                text="Next"
                id={currentSlide + "Next"}
                onClick={navigationOnClick}
                disabled={navigation}
                variant="filled"
                icon="right_white"
                iconPlacement="right"
              />
            </ShowComponent>
          </div>
        </div>
      </div>

      <div className="progress-bar justified">
        <div className="progress-bar-inner">
          <div
            style={{
              width: `${progress}%`,
            }}
            className="progress-bar-progress"
          ></div>
          <div className="progress-bar-progress-percent relative">
            <span
              style={{
                left: `${progress}%`,
              }}
              className="absolute"
            >
              {progress}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
