import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const ChangePad = () => {
  const changePad = [
    {
      type: "I change my pad every 1-2 hours",
      link: "/assets/logos/pcos_form/menstrual_cycles/Clock_1.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Clock_1.svg",
      typo: "",
    },
    {
      type: "I change my pad every 2-3 hours",
      link: "/assets/logos/pcos_form/menstrual_cycles/Clock_2.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Clock_2.svg",
      typo: "",
    },
    {
      type: "I change my pad every 3-5 hours",
      link: "/assets/logos/pcos_form/menstrual_cycles/Clock_3.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Clock_3.svg",
      typo: "",
    },
    {
      type: "I change my pad every 8 hours",
      link: "/assets/logos/pcos_form/menstrual_cycles/Clock_4.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Clock_4.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { pad_change_frequency } = formValues;

  useEffect(() => {
    if (pad_change_frequency === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [pad_change_frequency]);
  return (
    <CardRenderer
      data={changePad}
      value={pad_change_frequency}
      onClick={onClick}
      name="pad_change_frequency"
    />
  );
};

export default ChangePad;
