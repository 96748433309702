import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const PeriodBloodFrequency = () => {
  const bloodFrequency = [
    {
      type: "Light Bleeding",
      link: "/assets/logos/pcos_form/menstrual_cycles/Pad_2.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Pad_2_checked.svg",
      typo: "",
    },
    {
      type: "Normal Bleeding",
      link: "/assets/logos/pcos_form/menstrual_cycles/Pad_3.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Pad_3_checked.svg",
      typo: "",
    },
    {
      type: "Heavy Bleeding/Clots of blood",
      link: "/assets/logos/pcos_form/menstrual_cycles/Pad_4.svg",
      linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Pad_4_checked.svg",
      typo: "",
    },
    // {
    //   type: "Clots of blood",
    //   link: "/assets/logos/pcos_form/menstrual_cycles/Pad_1.svg",
    //   linkChecked: "/assets/logos/pcos_form/menstrual_cycles/Pad_1_checked.svg",
    //   typo: "",
    // },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { bleeding_amount } = formValues;

  useEffect(() => {
    if (bleeding_amount === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [bleeding_amount]);

  return (
    <CardRenderer
      data={bloodFrequency}
      value={bleeding_amount}
      onClick={onClick}
      name="bleeding_amount"
    />
  );
};

export default PeriodBloodFrequency;
