import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const DailyDiet = () => {
  const dailyDiet = [
    {
      type: "Mostly whole foods (Fruits, vegetables, lean proteins, whole grains)",
      link: "/assets/logos/pcos_form/daily_diet/Food_1.svg",
      linkChecked: "/assets/logos/pcos_form/daily_diet/Food_1_checked.svg",
      typo: "",
    },
    {
      type: "A mix of whole foods and processed foods",
      link: "/assets/logos/pcos_form/daily_diet/Food_2.svg",
      linkChecked: "/assets/logos/pcos_form/daily_diet/Food_2_checked.svg",
      typo: "",
    },
    {
      type: "Mostly processed foods (Fast food, sugary snacks)",
      link: "/assets/logos/pcos_form/daily_diet/Food_3.svg",
      linkChecked: "/assets/logos/pcos_form/daily_diet/Food_3_checked.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { daily_diet } = formValues;

  useEffect(() => {
    if (daily_diet === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [daily_diet]);
  return (
    <CardRenderer
      data={dailyDiet}
      value={daily_diet}
      onClick={onClick}
      name="daily_diet"
    />
  );
};

export default DailyDiet;
