import React, { useContext, useEffect } from "react";
import { slideHandleContext } from "../../../Context/SlideHandler";
import "./SliderContent.css";
import HeroSection from "../HeroSection/HeroSection";
import BottomSection from "../BottomSection/BottomSection";
import ShowComponent from "../ShowComponent/ShowComponent";
const SliderContent = ({ children, data }) => {
  const {
    currentSlide,
    assignPrevSlide,
    assignNextSlide,
    fieldEmpty,
    updateProgress,
  } = useContext(slideHandleContext);

  useEffect(() => {
    if (currentSlide === data.current) {
      assignPrevSlide(data.prev);
      assignNextSlide(data.nextSlide);
      updateProgress(data.progress);
    }
    
  }, [currentSlide]);

  if (currentSlide === data.current) {
    return (
      <div className="slide-component-wrapper-main ">
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
          }}
          className="slide-component-wrapper-inner"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
            }}
            className="slide-component-content vhHeight"
          >
            <div className="hero-section-slide-container">
              <HeroSection typo1={data.typo1} typo2={data.typo2} />
              <div
                style={{
                  minHeight: currentSlide === "BodyHair" ? "577px" : "450px",
                }}
                className="slide-content-container render-animation relative"
              >
                <div
                  style={{
                    marginBottom: "23px",
                  }}
                  className="typo-container justified flex_column"
                >
                  <ShowComponent condition={data.lightTypo}>
                    <div className="hero-section-typo-light justified">
                      <span>{data.lightTypo}</span>
                    </div>
                  </ShowComponent>
                  <ShowComponent condition={data.typo1}>
                    <div className="hero-section-typo justified">
                      <h2>{data.typo1}</h2>
                    </div>
                  </ShowComponent>
                  <ShowComponent condition={data.midTypo}>
                    <div className="mid-typo-container">
                      <span>{data.midTypo}</span>
                    </div>
                  </ShowComponent>
                  <ShowComponent condition={data.typo2}>
                    <div className="hero-section-second-typo justified">
                      <h3>{data.typo2}</h3>
                    </div>
                  </ShowComponent>
                </div>
                {children}
                <div
                  style={{
                    left: fieldEmpty ? "0" : "-100%",
                    opacity: fieldEmpty ? "1" : "0",
                  }}
                  className="fiels-empty-err-container absolute"
                >
                  <span>Fields must be filled</span>
                </div>
              </div>
            </div>
            {currentSlide !== "ResultChart" && <BottomSection />}
          </div>
        </div>
      </div>
    );
  } else return;
};

export default SliderContent;
