import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const DietaryPattern = () => {
  const dietary_patter = [
    {
      type: "Yes, it has helped me a lot",
      link: "/assets/logos/pcos_form/calenders/frequentSugar.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/frequentSugar.svg",
      typo: "",
    },
    {
      type: "Yes, but it didn't help",
      link: "/assets/logos/pcos_form/calenders/occasionSugar.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/occasionSugar.svg",
      typo: "",
    },
    {
      type: "No",
      link: "/assets/logos/pcos_form/calenders/rerely.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/rerely.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { dietery_pattern } = formValues;

  useEffect(() => {
    if (dietery_pattern === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [dietery_pattern]);
  return (
    <CardRenderer
      data={dietary_patter}
      value={dietery_pattern}
      onClick={onClick}
      name="dietery_pattern"
    />
  );
};

export default DietaryPattern;
