import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const BodyShape = () => {
  const bodyShape = [
    {
      type: "Rectangle",

      link: "/assets/logos/pcos_form/body_shape/Shape_4.svg",
      linkChecked: "/assets/logos/pcos_form/body_shape/Shape_4_checked.svg",
      typo: "",
    },

    {
      type: "Pear/Triangle",

      link: "/assets/logos/pcos_form/body_shape/Shape_5.svg",
      linkChecked: "/assets/logos/pcos_form/body_shape/Shape_5_checked.svg",
      typo: "",
    },
    {
      type: "Oval/Apple",

      link: "/assets/logos/pcos_form/body_shape/Shape_1.svg",
      linkChecked: "/assets/logos/pcos_form/body_shape/Shape_1_checked.svg",
      typo: "",
    },

    {
      type: "Inv. Triangle",

      link: "/assets/logos/pcos_form/body_shape/Shape_2.svg",
      linkChecked: "/assets/logos/pcos_form/body_shape/Shape_2_checked.svg",
      typo: "",
    },

    {
      type: "Hourglass",

      link: "/assets/logos/pcos_form/body_shape/Shape_3.svg",
      linkChecked: "/assets/logos/pcos_form/body_shape/Shape_3_checked.svg",
      typo: "",
    },
  ];

  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { body_shape } = formValues;

  useEffect(() => {
    if (body_shape === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [body_shape]);
  return (
    <CardRenderer
      data={bodyShape}
      value={body_shape}
      onClick={onClick}
      name="body_shape"
    />
  );
};

export default BodyShape;
