import React, { useEffect, useContext } from "react";
import BodyHairRenderer from "./BodyHairRenderer/BodyHairRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const BodyHair = () => {
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const {
    hair_uper_lip,
    hair_chin,
    hair_chest,
    hair_upper_abdomen,
    hair_lower_abdomen,
    hair_arms,
    hair_thighs,
    hair_upper_back,
    hair_lower_back,
  } = formValues;
  const rightContainerData = [
    {
      name: "UPPER ARMS",
      buttonName: "hair_arms",
      value: hair_arms,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Arms_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Arms_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Arms_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Arms_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Arms_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Arms_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Arms_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Arms_4_checked.svg",
        },
      ],
    },
    {
      name: "THIGHS",
      buttonName: "hair_thighs",
      value: hair_thighs,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Legs_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Legs_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Legs_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Legs_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Legs_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Legs_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Legs_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Legs_4_checked.svg",
        },
      ],
    },

    {
      name: "UPPER BACK",
      buttonName: "hair_upper_back",
      value: hair_upper_back,

      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Back_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Back_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Back_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Back_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Back_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Back_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Back_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Back_4_checked.svg",
        },
      ],
    },
    {
      name: "LOWER BACK",
      buttonName: "hair_lower_back",
      value: hair_lower_back,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_BottomBack_1.svg",
          content2:
            "/assets/logos/pcos_form/body_hair/H_BottomBack_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_BottomBack_2.svg",
          content2:
            "/assets/logos/pcos_form/body_hair/H_BottomBack_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_BottomBack_3.svg",
          content2:
            "/assets/logos/pcos_form/body_hair/H_BottomBack_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_BottomBack_4.svg",
          content2:
            "/assets/logos/pcos_form/body_hair/H_BottomBack_4_checked.svg",
        },
      ],
    },
  ];
  const leftContainerData = [
    {
      name: "UPPER LIP",
      buttonName: "hair_uper_lip",
      value: hair_uper_lip,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Lips_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Lips_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Lips_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Lips_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Lips_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Lips_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Lips_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Lips_4_checked.svg",
        },
      ],
    },
    {
      name: "CHIN",
      buttonName: "hair_chin",
      value: hair_chin,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Mouth_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Mouth_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Mouth_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Mouth_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Mouth_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Mouth_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Mouth_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Mouth_4_checked.svg",
        },
      ],
    },
    {
      name: "CHEST",
      buttonName: "hair_chest",
      value: hair_chest,
      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Breast_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Breast_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Breast_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Breast_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Breast_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Breast_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Breast_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Breast_4_checked.svg",
        },
      ],
    },
    {
      name: "UPPER ABDOMEN",
      buttonName: "hair_upper_abdomen",
      value: hair_upper_abdomen,

      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Belly_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Belly_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Belly_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Belly_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Belly_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Belly_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Belly_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Belly_4_checked.svg",
        },
      ],
    },
    {
      name: "LOWER ABDOMEN",
      buttonName: "hair_lower_abdomen",
      value: hair_lower_abdomen,

      buttons: [
        {
          content: "No Hair",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Bottom_1.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Bottom_1_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Bottom_2.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Bottom_2_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Bottom_3.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Bottom_3_checked.svg",
        },
        {
          content: "/assets/logos/pcos_form/body_hair/H_Bottom_4.svg",
          content2: "/assets/logos/pcos_form/body_hair/H_Bottom_4_checked.svg",
        },
      ],
    },
  ];

  useEffect(() => {
    if (
      hair_uper_lip === "" ||
      hair_chin === "" ||
      hair_chest === "" ||
      hair_upper_abdomen === "" ||
      hair_lower_abdomen === "" ||
      hair_arms === "" ||
      hair_thighs === "" ||
      hair_upper_back === "" ||
      hair_lower_back === ""
    ) {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [
    hair_uper_lip,
    hair_chin,
    hair_chest,
    hair_upper_abdomen,
    hair_lower_abdomen,
    hair_arms,
    hair_thighs,
    hair_upper_back,
    hair_lower_back,
  ]);

  return (
    <div className="body-hair-main">
      <div className="body-hair-inner">
        <div
          style={{
            gap: "40px",
          }}
          className="body-hair-content my_row justify_center align_start"
        >
          <div className="left-container">
            {leftContainerData.map(
              ({ name, buttons, buttonName, value }, index) => (
                <BodyHairRenderer
                  key={index}
                  buttons={buttons}
                  name={name}
                  value={value}
                  buttonName={buttonName}
                  onClick={onClick}
                />
              )
            )}
          </div>

          <div className="right-container">
            {rightContainerData.map(
              ({ name, buttons, buttonName, value }, index) => (
                <BodyHairRenderer
                  key={index}
                  buttons={buttons}
                  name={name}
                  value={value}
                  buttonName={buttonName}
                  onClick={onClick}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyHair;
