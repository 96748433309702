import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const SugaryFoodConsuption = () => {
  const sugaryFoodConsuption = [
    {
      type: "Rarely or never",
      link: "/assets/logos/pcos_form/calenders/rerely.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/rerely.svg",
      typo: "",
    },
    {
      type: "Occasionally (Once a week or less)",
      link: "/assets/logos/pcos_form/calenders/occasionSugar.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/occasionSugar.svg",
      typo: "",
    },
    {
      type: "Frequently (Several times a week or daily)",
      link: "/assets/logos/pcos_form/calenders/frequentSugar.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/frequentSugar.svg",
      typo: "",
    },
    
    
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { sugary_items } = formValues;

  useEffect(() => {
    if (sugary_items === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [sugary_items]);
  return (
    <CardRenderer
      data={sugaryFoodConsuption}
      value={sugary_items}
      onClick={onClick}
      name="sugary_items"
    />
  );
};

export default SugaryFoodConsuption;
