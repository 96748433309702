import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const PhysicalActivity = () => {
  const dietary_patter = [
    {
      type: "Regularly (2-3 times a week)",
      link: "/assets/logos/pcos_form/calenders/regular.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/regular.svg",
      typo: "",
    },
    {
      type: "Occasionally (Once a week or less)",
      link: "/assets/logos/pcos_form/calenders/infrequent.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/infrequent.svg",
      typo: "",
    },
    {
      type: "Rarely or Never",
      link: "/assets/logos/pcos_form/calenders/rerely.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/rerely.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { physical_activity } = formValues;

  useEffect(() => {
    if (physical_activity === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [physical_activity]);
  return (
    <CardRenderer
      data={dietary_patter}
      value={physical_activity}
      onClick={onClick}
      name="physical_activity"
    />
  );
};

export default PhysicalActivity;
