import { ProgressContextProvider } from "./Context/ProgressContext";
import { SlideHandlerProvider } from "./Context/SlideHandler";
import { FormValuesProvider } from "./Context/ValuesContext";
import Route from "./Router/Router";
import Index from "./components/Index";

function App() {
  return (
    <ProgressContextProvider>
      <SlideHandlerProvider>
        <FormValuesProvider>
          <Index />
        </FormValuesProvider>
      </SlideHandlerProvider>
    </ProgressContextProvider>
  );
}

export default App;
