import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
import "./HRUFeeling.css";

const HRUFeeling = () => {
  const HRUFeelings = [
    {
      type: "I feel happy",
      link: "/assets/logos/pcos_form/feeling/Happy.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/Happy_checked.svg",
      typo: "",
    },
    {
      type: "I feel sad/low",
      link: "/assets/logos/pcos_form/feeling/Sad.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/Sad_checked.svg",
      typo: "",
    },
    {
      type: "I feel anxious",
      link: "/assets/logos/pcos_form/feeling/Anxious.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/Anxious_checked.svg",
      typo: "",
    },
    {
      type: "I feel like crying",
      link: "/assets/logos/pcos_form/feeling/Crying.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/Crying_checked.svg",
      typo: "",
    },
    {
      type: "I feel like angry/irritable",
      link: "/assets/logos/pcos_form/feeling/Angry.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/Angry_checked.svg",
      typo: "",
    },
    {
      type: "I feel lethargic",
      link: "/assets/logos/pcos_form/feeling/lethargic.svg",
      linkChecked: "/assets/logos/pcos_form/feeling/lethargic_checked.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { day_mood } = formValues;

  useEffect(() => {
    if (!day_mood.length) {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [day_mood.length]);
  return (
    <CardRenderer
      data={HRUFeelings}
      value={day_mood}
      onClick={onClick}
      name="day_mood"
      addClass="multipleSelection"
    />
  );
};

export default HRUFeeling;
