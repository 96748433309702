import React, { useState, useEffect, useContext } from "react";
import "./ReceiveResult.css";
import ShowComponent from "../Common/ShowComponent/ShowComponent";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";

const ReceiveResult = () => {
  const [resultMethod, setResultMethod] = useState("whatsapp");
  const changeMethod = (e) => {
    const { name } = e.target;
    setResultMethod(name);
  };
  const {
    formValues,
    onChange,
    formMessage,
    otpSent,
    handleEdit,
    otpVerified,
    getOtp,
    verifyOtp,
    timer,
    setTimer,
    emailError,
    setEmailError,
  } = useContext(formValuesContext);
  const { email, phone_number, otp } = formValues;
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);
  useEffect(() => {
  // Ensure phone_number length is exactly 11 characters
  const sanitizedPhoneNumber = phone_number.replace(/\D/g, "");
  if (email && sanitizedPhoneNumber.length === 11) {
    activeNavigation();
    console.log("Activating Navigation");
  } else {
    inactiveNavigation();
    console.log("Deactivating Navigation");
  }
}, [email, phone_number, activeNavigation, inactiveNavigation]);


  useEffect(() => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, [email]);

  return (
    <div className="get-results-main">
      <div className="get-results-inner justified">
        <div className="get-results-content width justified">
          <button
            name="whatsapp"
            className={`receive-results-button my_row justify_start align_center ${resultMethod === "whatsapp" ? "selected-method" : "not-selected"
              } click-capture`}
            onClick={changeMethod}
          >
            <div className="contact-icon-container justified">
              <img src="/assets/icons/whatsapp.png" alt="" />
            </div>
            Contact Details
          </button>
          {/* <button
            name="email"
            className={`receive-results-button my_row justify_start align_center ${
              resultMethod === "email" ? "selected-method" : "not-selected"
            } click-capture`}
            onClick={changeMethod}
          >
            <div className="contact-icon-container justified">
              <img src="/assets/icons/social.png" alt="" />
            </div>
            Email
          </button> */}
        </div>
        <div className="get-result-form">
          <div className="form-inputs-container">
            <ShowComponent condition={resultMethod === "whatsapp"}>
              <div className="form-input-main-container my_row align_center space_between">
                <input
                  name="email"
                  type="email"
                  className="height second-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={onChange}
                />
                <ShowComponent condition={email}>
                  <button
                    className={`otp-action-button ${!emailError ? "enabled" : "disabled"
                      }`}
                  >
                    {emailError === true ? "Invalid" : "Valid"}
                  </button>
                </ShowComponent>
              </div>

              <div className="form-input-main-container my_row align_center space_between">
                <button className="country-code-container justified">
                  <div className="country-img justified">
                    <img src="/assets/flag.jpeg" alt="" />
                  </div>
                </button>

                {/* <input
                  name="phone_number"
                  className="height phone-number-input"
                  type="number"
                  placeholder="03XXXXXXXXX"
                  value={phone_number}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    const currentValue = phone_number || "";
                    const sanitizedValue = currentValue
                      .replace(/\D/g, "")
                      .slice(0, 11);
                    if (
                      currentValue === "03" &&
                      (e.key === "Backspace" || e.key === "Delete")
                    ) {
                      e.preventDefault();
                    }
                    if (sanitizedValue.length > 11) {
                      e.preventDefault();
                    }
                  }}
                  maxLength="11"
                /> */}



                <input
                  name="phone_number"
                  className="height phone-number-input"
                  type="number"
                  placeholder="03XXXXXXXXX"
                  value={phone_number}
                  onChange={onChange}
                  onKeyDown={(e) => {
                    const currentValue = phone_number || "";
                    const sanitizedValue = currentValue
                      .replace(/\D/g, "")
                      .slice(0, 11);
                    if (
                      currentValue === "03" &&
                      (e.key === "Backspace" || e.key === "Delete")
                    ) {
                      e.preventDefault();
                    }
                    if (sanitizedValue.length > 11) {
                      e.preventDefault();
                    }
                    if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                      e.preventDefault();
                    }
                  }}
                  maxLength="11"
                />

                {otpSent ? (
                  <button style={{
                    visibility: "hidden"
                  }} className="otp-action-button disabled">Sent</button>
                ) : (
                  <button
                    id="GET_OTP"
                    style={{
                      visibility: "hidden"
                    }} 
                    className={`otp-action-button enabled ${phone_number.length < 11 && "visibility-none"
                      }`}
                    onClick={getOtp}
                  >
                    Get OTP
                  </button>
                )}
              </div>
              {emailError}
            </ShowComponent>

            {/* <ShowComponent condition={resultMethod === "whatsapp"}>
              <div className="form-input-main-container my_row align_center space_between">
                <input
                  className="height second-input"
                  type="text"
                  name="otp"
                  placeholder="Verify OTP"
                  value={otp}
                  onChange={onChange}
                />

                {otpVerified ? (
                  <button
                    className="otp-action-button  
                   disabled"
                    disabled={!otpSent}
                  >
                    Verified
                  </button>
                ) : (
                  <button
                    id="Verify_OTP"
                    className={`otp-action-button ${otpSent ? "enabled" : "disabled"
                      }`}
                    onClick={verifyOtp}
                    disabled={!otpSent}
                  >
                    Verify OTP
                  </button>
                )}
              </div>
            </ShowComponent> */}

            <ShowComponent
              condition={resultMethod === "whatsapp" && otpSent && !otpVerified}
            >
              <div className="resend-otp-button-containe justified flex_column">
                {timer === 0 ? (
                  <button
                    id="Resend_OTP"
                    className="reset-otp-button justified"
                    onClick={getOtp}
                  >
                    <div className="refresh-icon-contianer justified">
                      <img src="/assets/icons/refresh.png" alt="" />
                    </div>
                    <span>Now Resend OTP</span>
                  </button>
                ) : (
                  <>
                    <button className="reset-otp-button justified">
                      <span>Resend in {timer}</span>
                    </button>
                    <button
                      style={{
                        color: "red",
                        borderBottom: "1px solid red",
                      }}
                      className="reset-otp-button justified"
                      onClick={handleEdit}
                    >
                      <span>Edit Number?</span>
                    </button>
                  </>
                )}
              </div>
            </ShowComponent>

            <div className="checkbox-container justified">
              <form className="checkbox-form">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                <div className="label-container">
                  <label className="checkboxLabel" htmlFor="html">
                    I would like to schedule a free consultation with a PCOS
                    expert to follow up on the results.
                  </label>
                </div>
              </form>
            </div>
            <div className="form-response justified">
              <span className="submit-success">{formMessage}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiveResult;
