import React from "react";
import GaugeChart from "react-gauge-chart";

const Chart = ({ typo, score, children }) => {
  return (
    <div className="psoc-risk-score justified flex_column chart-size">
      <p className="chart-label">
        {typo}
        <span>{parseInt(score)}</span>
      </p>
      {children}
      <div className="risk-guage-chart justified relative chart-size">
        <div className="low-psoc absolute risk-frequency-text range-1"
        >
          <p>LOW</p>
        </div>
        <div

          className="moderate-psoc absolute risk-frequency-text range-2"
        >
          <p>MODERATE</p>
        </div>
        <div

          className="high-psoc absolute risk-frequency-text range-3"
        >
          <p>HIGH</p>
        </div>
        <GaugeChart
          id="gauge-chart2"
          className="chart-size"
          nrOfLevels={3}
          percent={parseFloat(score / 100)}
          colors={["#8CD47E", "#F8D66D", "#FF6961"]}
          arcWidth={0.3}
          hideText={true}
        />
      </div>
    </div>
  );
};

export default Chart;
