import axios from "axios";
import { useState, useEffect, createContext, useContext } from "react";
import { slideHandleContext } from "./SlideHandler";
const formValuesContext = createContext();

const FormValuesProvider = ({ children }) => {
  const initialValues = {
    age: "",
    occupation: "",
    height: "",
    body_weight: "",
    periods_frequency: "",
    bleeding_days: "",
    bleeding_amount: "",
    pad_change_frequency: "",
    bodypart_notice: [], //array
    hair_arms: "",
    hair_upper_back: "",
    hair_upper_abdomen: "",
    hair_lower_abdomen: "",
    hair_chest: "",
    hair_uper_lip: "",
    hair_chin: "",
    hair_thighs: "",
    hair_lower_back: "",
    body_shape: "",
    day_mood: [], //array
    sleep: "",
    daily_diet: "",
    food_cravings: "",
    sugary_items: "",
    dietery_pattern: "",
    physical_activity: "",
    stress_level: "",
    lifestyle_included: false,
    email: "",
    country_code: "+92",
    phone_number: "03",
    otp: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formMessage, setFormMessage] = useState("");
  const [otpSent, setOtpSent] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [results, setResults] = useState({});
  const { assignCurrentSlide } = useContext(slideHandleContext);
  const [timer, setTimer] = useState(0);
  const [emailError, setEmailError] = useState(true);
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const resetForm = () => {
    setFormValues({
      age: "",
      occupation: "",
      height: "",
      body_weight: "",

      periods_frequency: "",

      bleeding_days: "",

      bleeding_amount: "",

      pad_change_frequency: "",

      bodypart_notice: [], //array

      hair_arms: "",
      hair_upper_back: "",
      hair_upper_abdomen: "",
      hair_lower_abdomen: "",
      hair_chest: "",
      hair_uper_lip: "",
      hair_chin: "",
      hair_thighs: "",
      hair_lower_back: "",

      body_shape: "",

      day_mood: [], //array

      sleep: "",

      daily_diet: "",

      food_cravings: "",

      sugary_items: "",

      dietery_pattern: "",

      physical_activity: "",

      stress_level: "",

      email: "",
      country_code: "+92",
      phone_number: "03",
      otp: "",
    });

    setOtpSent("");
    setOtpVerified(false);
    setFormMessage("");
  };
  let timerFunc;

  useEffect(() => {
    if (timer <= 0) {
      clearTimeout(timerFunc);
      return;
    }
    if (timer > 0) {
      timerFunc = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerFunc);
    };
  }, [timer]);

  const resetTimer = () => {
    clearTimeout(timerFunc);
    setTimer(0);
  };
  const getOtp = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}/api/v1/pcos/send_otp.json`, {
        otp: {
          phone_number: `+92${formValues.phone_number.substring(1, 11)}`,
        },
      })
      .then((res) => {
        setFormMessage(res.data.message);
        setOtpSent(res.data.success);
        setTimer(119);
      })
      .catch((res) => {
        setFormMessage(res.data.message);
        setOtpSent(res.data.success);
        setTimer(0);
      });
  };
  const verifyOtp = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_LINK}/api/v1/pcos/verify_otp.json`,
        {
          otp: {
            phone_number: `+92${formValues.phone_number.substring(1, 11)}`,
            code: formValues.otp,
          },
        }
      )
      .then((res) => {
        setFormMessage(res.data.message);
        setOtpVerified(res.data.success);
        setTimer(0);
      })
      .catch((res) => {
        setFormMessage(res.data.message);
        setOtpVerified(res.data.success);
      });
  };
  const submitForm = () => {
    setSubmitInProgress(true)
    if (submitInProgress) { return }
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}/api/v1/pcos/forms.json`, {
        pcos_form: {
          ...formValues,
        },
      })
      .then((res) => {
        setFormMessage(res.data.message);
        setResults(res.data.data);
        assignCurrentSlide("ResultChart");
        clearTimeout(timerFunc);
        setSubmitInProgress(false)
      })
      .catch((res) => {
        setFormMessage(res.data.message);
        setSubmitInProgress(false)
      });
  };
  const handleEdit = () => {
    resetTimer();
    setFormMessage("");
    setOtpSent(false);
    setOtpVerified(false);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && value.length > 11) {
      return;
    }
    if (
      name === "email" ||
      name === "phone_number" ||
      name === "occupation" ||
      name === "otp"
    ) {
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  const onClick = (e) => {
    const { name, id, classList } = e.target;

    if (classList.contains("multipleSelection")) {
      if (classList.contains("mToS") && parseInt(id) === 0) {
        if (formValues[name].includes(parseInt(id))) {
          setFormValues({
            ...formValues,
            [name]: [],
          });
        } else {
          setFormValues({
            ...formValues,
            [name]: [0],
          });
        }
      } else {
        if (formValues[name].includes(parseInt(id))) {
          setFormValues({
            ...formValues,
            [name]: formValues[name].filter((item) => item !== parseInt(id)),
          });
        } else {
          if (classList.contains("mToS")) {
            setFormValues({
              ...formValues,
              [name]: [
                ...formValues[name].filter((item) => parseInt(item) !== 0),
                parseInt(id),
              ],
            });
          } else {
            setFormValues({
              ...formValues,
              [name]: [...formValues[name], parseInt(id)],
            });
          }
        }
      }
    } else {
      if (formValues[name] == id) {
        setFormValues({ ...formValues, [name]: "" });
      } else {
        setFormValues({ ...formValues, [name]: parseFloat(id) });
      }
    }
  };

  return (
    <formValuesContext.Provider
      value={{
        formValues,
        onChange,
        onClick,
        submitForm,
        formMessage,
        otpSent,
        handleEdit,
        otpVerified,
        getOtp,
        verifyOtp,
        results,
        resetForm,
        setFormValues,
        timer,
        setTimer,
        emailError,
        setEmailError,
        submitInProgress
      }}
    >
      {children}
    </formValuesContext.Provider>
  );
};

export { formValuesContext, FormValuesProvider };
