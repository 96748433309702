import React from "react";
import "./Button.css";
import ShowComponent from "../ShowComponent/ShowComponent";

const Button = ({
  text,
  onClick,
  disabled,
  name,
  variant,
  icon,
  iconPlacement,
  border,
  addDisabled,
  id,
}) => {
  if (addDisabled) {
    return (
      <button
        id={id ? id : "button"}
        name={name ? name : "name"}
        className={`common-button ${
          disabled ? "disabled" : variant
        } justified ${
          border ? "border-button" : "non-border-button"
        }  click-capture`}
        onClick={onClick}
        disabled={disabled}
      >
        <ShowComponent condition={iconPlacement === "left"}>
          <span className="justified">
            <img src={`/assets/icons/${icon}.svg`} alt="icon" />
          </span>
        </ShowComponent>
        {text}
        <ShowComponent condition={iconPlacement === "right"}>
          <span className="justified">
            <img src={`/assets/icons/${icon}.svg`} alt="icon" />
          </span>
        </ShowComponent>
      </button>
    );
  } else
    return (
      <button
        id={id ? id : "button"}
        name={name ? name : "name"}
        className={`common-button ${
          disabled ? "disabled" : variant
        } justified ${
          border ? "border-button" : "non-border-button"
        }  click-capture`}
        onClick={onClick}
        // disabled={disabled}
      >
        <ShowComponent condition={iconPlacement === "left"}>
          <span className="justified">
            <img src={`/assets/icons/${icon}.svg`} alt="icon" />
          </span>
        </ShowComponent>
        {text}
        <ShowComponent condition={iconPlacement === "right"}>
          <span className="justified">
            <img src={`/assets/icons/${icon}.svg`} alt="icon" />
          </span>
        </ShowComponent>
      </button>
    );
};

export default Button;
