import React, { useState } from "react";
import "./CardRenderer.css";
import ShowComponent from "../ShowComponent/ShowComponent";
const CardRenderer = ({ data, onClick, value, name, addClass, mToS }) => {
  const renderFeelings = data.map(
    ({ type, link, typo, linkChecked }, index) => {
      return (
        <div
          key={index}
          className={`card-renderer-content my_row flex_column ${
            addClass
              ? value.includes(index)
                ? "selected"
                : "not-selected"
              : value === index
              ? "selected"
              : "not-selected"
          }`}
        >
          <button
            style={{
              backgroundImage: `url(${
                addClass
                  ? value.includes(index)
                    ? linkChecked
                    : link
                  : value === index
                  ? linkChecked
                  : link
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
            id={index}
            name={name}
            className={`card-renderer-button justified  ${
              addClass ? addClass : "singleSelection"
            } ${mToS ? "mToS" : ""}`}
            onClick={onClick}
          ></button>
          <div className="card-renderer-button-caption">
            <span>{type}</span>
          </div>
          <ShowComponent condition={typo}>
            <div className="card-renderer-typo">
              <span>{typo}</span>
            </div>
          </ShowComponent>
        </div>
      );
    }
  );
  return (
    <div className="card-renderer-main">
      <div className="card-renderer-inner width my_row justify_center">
        {renderFeelings}
      </div>
    </div>
  );
};

export default CardRenderer;
