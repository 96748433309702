import React, { useState } from "react";
import "./GtnInputs.css";

const GtnInputs = ({ type, label, content, img, value, name, onChange }) => {
  const [focused, setFocused] = useState(false);
  const setFocus = () => {
    setFocused(!focused);
  };
  return (
    <div
      className={`gtn-input-main my_row align_center justify_start ${
        focused ? "focused-input" : "blured-input"
      }`}
    >
      <div className="gtn-ico justified">
        <img src={img} alt={type} />
      </div>
      {type === "dropdown" ? (
        <select
          className="gtn-input gtn-input-dropdown-width height"
          name={name}
          value={value}
          onChange={onChange}
        >
          <option value="">What is your occupation?</option>
          <option value="0">Home maker</option>
          <option value="1">Student</option>
          <option value="2">Working professional</option>
        </select>
      ) : (
        <input
          type={type}
          className="gtn-input-width gtn-input height"
          placeholder={content}
          onFocus={setFocus}
          onBlur={setFocus}
          value={value}
          name={name}
          onChange={onChange}
        />
      )}
      <div className="gtn-button">
        {type !== "dropdown" && (
          <div className="label-button justified">{label}</div>
        )}
      </div>
    </div>
  );
};

export default GtnInputs;
