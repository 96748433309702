import React, { useContext } from "react";
import GetToKnow from "./GetToKnow/GetToKnow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { progressBarContext } from "../Context/ProgressContext";
import HRUFeeling from "./HRUFeeling/HRUFeeling";
import OftenPeriods from "./OftenPeriods/OftenPeriods";
import PeriodBloodFrequency from "./PeriodBloodFrequency/PeriodBloodFrequency";
import PeriodDays from "./PeriodDays/PeriodDays";
import SleepStatus from "./SleepStatus/SleepStatus";
import BodyShape from "./BodyShape/BodyShape";
import ReceiveResult from "./ReceiveResult/ReceiveResult";
import SliderContent from "./Common/SliderContent/SliderContent";
import ChangePad from "./ChangePad/ChangePad";
import DailyDiet from "./DailyDiet/DailyDiet";
import BodySymptoms from "./BodySymptoms/BodySymptoms";
import FoodCravings from "./FoodCravings/FoodCravings";
import SugaryFoodConsuption from "./SugaryFoodConsuption/SugaryFoodConsuption";
import DietaryPattern from "./DietaryPattern/DietaryPattern";
import PhysicalActivity from "./PhysicalActivity/PhysicalActivity";
import ContinueOrReport from "./ContinueOrReport/ContinueOrReport";
import StressLevel from "./StressLevel/StressLevel";
import BodyHair from "./BodyHair/BodyHair";
import ResultChart from "./ResultChart/ResultChart";
import { formValuesContext } from "../Context/ValuesContext";
import data from "./data";
const SectionSlider = () => {
  const { formValues } = useContext(formValuesContext);

  const { lifestyle_included } = formValues;
  const {
    getToKnow,
    ofterPeriods,
    periodDays,
    periodBloodFrequency,
    changePad,
    bodySymptoms,
    bodyHair,
    bodyShape,
    hruFeeling,
    sleepStatus,

    continueOrReport,
    dailyDiet,
    receiveResult,
    receiveResultTwo,
    foodCravings,
    sugaryFoodConsuption,
    dietaryPattern,
    physicalActivity,
    stressLevel,
    resultChart,
  } = data;
  return (
    <>
      <SliderContent data={getToKnow}>
        <GetToKnow />
      </SliderContent>

      <SliderContent data={ofterPeriods}>
        <OftenPeriods />
      </SliderContent>

      <SliderContent data={periodDays}>
        <PeriodDays />
      </SliderContent>

      <SliderContent data={periodBloodFrequency}>
        <PeriodBloodFrequency />
      </SliderContent>

      <SliderContent data={changePad}>
        <ChangePad />
      </SliderContent>

      <SliderContent data={bodySymptoms}>
        <BodySymptoms />
      </SliderContent>

      <SliderContent data={bodyHair}>
        <BodyHair />
      </SliderContent>

      <SliderContent data={bodyShape}>
        <BodyShape />
      </SliderContent>

      <SliderContent data={hruFeeling}>
        <HRUFeeling />
      </SliderContent>

      <SliderContent data={sleepStatus}>
        <SleepStatus />
      </SliderContent>

      <SliderContent data={continueOrReport}>
        <ContinueOrReport />
      </SliderContent>

      <SliderContent
        data={lifestyle_included ? receiveResultTwo : receiveResult}
      >
        <ReceiveResult />
      </SliderContent>

      <SliderContent data={dailyDiet}>
        <DailyDiet />
      </SliderContent>

      <SliderContent data={foodCravings}>
        <FoodCravings />
      </SliderContent>

      <SliderContent data={sugaryFoodConsuption}>
        <SugaryFoodConsuption />
      </SliderContent>

      <SliderContent data={dietaryPattern}>
        <DietaryPattern />
      </SliderContent>

      <SliderContent data={physicalActivity}>
        <PhysicalActivity />
      </SliderContent>

      <SliderContent data={stressLevel}>
        <StressLevel />
      </SliderContent>

      <SliderContent data={resultChart}>
        <ResultChart />
      </SliderContent>
    </>
  );
};

export default SectionSlider;
