import React, { useEffect, useContext } from "react";
import "./PeriodDays.css";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const PeriodDays = () => {
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { bleeding_days } = formValues;

  useEffect(() => {
    if (bleeding_days === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [bleeding_days]);
  return (
    <div className="range-button-main">
      <div className="numbers-inner">
        <div className="numbers-inner width justified">
          <div
            style={{
              width: "212px",
              height: "164px",
              border: "2px solid #000000",
              borderRadius: "20px 20px 0px 0px",
              color: bleeding_days === 0 ? "#fff" : "#0f138c",
              backgroundColor: bleeding_days === 0 ? "#0f138c" : "transparent",
            }}
            className="days-button-container relative numbers-button"
          >
            <button
              style={{
                border: "none",
                left: "0",
                top: "0",
              }}
              id="0"
              className="renge-button width height absolute"
              name="bleeding_days"
              onClick={onClick}
            ></button>
            <div
              style={{
                fontSize: "42px",
              }}
              className="width"
            >
              <p>1-2</p>
            </div>
            <div
              style={{
                fontSize: "22px",
              }}
              className="width"
            >
              <p>Days</p>
            </div>
          </div>
          <div
            style={{
              width: "212px",
              height: "164px",
              border: "2px solid #000000",
              borderRadius: "20px 20px 0px 0px",
              color: bleeding_days === 1 ? "#fff" : "#0f138c",
              backgroundColor: bleeding_days === 1 ? "#0f138c" : "transparent",
            }}
            className="days-button-container relative numbers-button"
          >
            <button
              style={{
                border: "none",
                left: "0",
                top: "0",
              }}
              id="1"
              className="renge-button width height absolute"
              name="bleeding_days"
              onClick={onClick}
            ></button>
            <div
              style={{
                fontSize: "42px",
              }}
              className="width"
            >
              <p>3 -10</p>
            </div>
            <div
              style={{
                fontSize: "22px",
              }}
              className="width"
            >
              <p>Days</p>
            </div>
          </div>
          <div
            style={{
              width: "212px",
              height: "164px",
              border: "2px solid #000000",
              borderRadius: "20px 20px 0px 0px",
              color: bleeding_days === 2 ? "#fff" : "#0f138c",
              backgroundColor: bleeding_days === 2 ? "#0f138c" : "transparent",
            }}
            className="days-button-container relative numbers-button"
          >
            <button
              style={{
                border: "none",
                left: "0",
                top: "0",
              }}
              id="2"
              className="renge-button width height absolute"
              name="bleeding_days"
              onClick={onClick}
            ></button>
            <div
              style={{
                fontSize: "42px",
              }}
              className="width"
            >
              <p>10+</p>
            </div>
            <div
              style={{
                fontSize: "22px",
              }}
              className="width"
            >
              <p>Days</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodDays;
