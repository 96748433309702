import React, { useContext } from "react";
import Button from "../Common/Button/Button";
import { slideHandleContext } from "../../Context/SlideHandler";
import { formValuesContext } from "../../Context/ValuesContext";

const ContinueOrReport = () => {
  const { setFormValues, formValues } = useContext(formValuesContext);
  const { assignCurrentSlide } = useContext(slideHandleContext);
  const handleShowResults = () => {
    assignCurrentSlide("ReceiveResult");
    setFormValues({ ...formValues, lifestyle_included: false });
  };
  const handleContinue = () => {
    assignCurrentSlide("DailyDiet");
    setFormValues({ ...formValues, lifestyle_included: true });
  };
  const availableFunctions = {
    showResults: handleShowResults,
    continue: handleContinue,
  };
  const handleOnClick = (e) => {
    const { name } = e.target;
    availableFunctions[name]();
  };
  return (
    <div style={{}} className="select-continue-main">
      <div className="continue-or-report-main">
        <div className="continue-or-report-inner">
          <div
            style={{
              gap: "40px",
            }}
            className="continue-or-report-content width justified"
          >
            <Button
              id="Submit_NO_SHOW_RESULT"
              text="NO, SHOW RESULT"
              onClick={handleOnClick}
              disabled={false}
              name="showResults"
              border={true}
              variant="text-only"
            />
            <Button
              id="YES CONTINUE"
              text="YES CONTINUE"
              onClick={handleOnClick}
              disabled={false}
              name="continue"
              variant="filled"
              iconPlacement="right"
              icon="right_white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueOrReport;
