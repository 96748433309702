const data = {
  getToKnow: {
    prev: "home",
    current: "GetToKnow",
    nextSlide: "OftenPeriods",

    typo1: "Let us get to know you better!",
    typo2:
      "To provide you with the best service and care, we need some basic information about your health and physical conditions. Please fill in the following fields with your current data:",
    progress: 0,
  },
  ofterPeriods: {
    prev: "GetToKnow",
    current: "OftenPeriods",
    nextSlide: "PeriodDays",
    typo1: "How often do you get your periods?",
    typo2:
      "Please select the option that best describes your menstrual cycle. This information will help us to better understand your overall health.",
    progress: 10,
  },
  periodDays: {
    prev: "OftenPeriods",
    current: "PeriodDays",
    nextSlide: "PeriodBloodFrequency",
    typo1: "For how many days do you bleed when you are on your periods?",
    progress: 20,
  },
  periodBloodFrequency: {
    prev: "PeriodDays",
    current: "PeriodBloodFrequency",
    nextSlide: "ChangePad",
    typo1: "How heavy is your bleeding during your period?",
    // typo2: "You can select more than one option",

    progress: 30,
  },
  changePad: {
    prev: "PeriodBloodFrequency",
    current: "ChangePad",
    nextSlide: "BodySymptoms",
    typo1: "How often do you change your pad?",
    typo2:
      "Please select the option that best describes your menstrual cycle. This information will help us to better understand your overall health.",
    progress: 40,
  },
  bodySymptoms: {
    prev: "ChangePad",
    current: "BodySymptoms",
    nextSlide: "BodyHair",
    typo1: "Do you notice any of the following on any part of your body?",
    typo2:
      "Please mark first option only if you notice dark and thick hair growth as compared to fine/thin hair. Please select all that apply.",
    progress: 50,
  },
  bodyHair: {
    prev: "BodySymptoms",
    current: "BodyHair",
    nextSlide: "BodyShape",
    typo1: "Please mark your body hair!",
    typo2:
      "While selecting the options, please refer to the images attached. If you have no body hair in any of the areas, select the option no hair. Please mark the score considering noticeable hair growth (thick hair) and not baby hair (thin hair).",
    progress: 60,
  },
  bodyShape: {
    prev: "BodyHair",
    current: "BodyShape",
    nextSlide: "HRUFeeling",
    typo1: "What do you think relates the most to your body shape?",
    progress: 70,
  },
  hruFeeling: {
    prev: "BodyShape",
    current: "HRUFeeling",
    nextSlide: "SleepStatus",
    typo1: "How is your mood mostly throughout the day?",
    typo2: "You can select more than one option.",
    progress: 80,
  },
  sleepStatus: {
    prev: "HRUFeeling",
    current: "SleepStatus",
    nextSlide: "ContinueOrReport",
    typo1: "How is your sleep?",
    typo2:
      "Please select the option that best describes your sleep status. If you are unsure about which option to select, please select the option that is closest to your experience.",
    progress: 90,
  },

  continueOrReport: {
    prev: "none",
    current: "ContinueOrReport",
    nextSlide: "none",
    lightTypo: "You are almost there!",
    midTypo:
      "WOULD YOU LIKE TO GET A RISK ASSESMENT OF PCOS BASED ON YOUR LIFESTYLE FOR A MORE DETAILED REPORT?",
    typo2:
      "Lifestyle can contribute to the risk factors associated with PCOS. Your responses will help us understand any potential lifestyle-related factors that may influence your risk of developing PCOS or exacerbating its symptoms.",
    progress: 100,
  },

  receiveResult: {
    prev: "SleepStatus",
    current: "ReceiveResult",
    nextSlide: "formSubmit",
    typo1: "How would you like to receive your result?",
    progress: 100,
  },
  receiveResultTwo: {
    prev: "StressLevel",
    current: "ReceiveResult",
    nextSlide: "formSubmit",
    typo1: "How would you like to receive your result?",
    progress: 100,
  },

  resultChart: {
    prev: "none",
    current: "ResultChart",
    nextSlide: "none",
    typo1: "Book a free consultation with PCOS expert!",
    progress: 100,
  },
  dailyDiet: {
    prev: "ContinueOrReport",
    current: "DailyDiet",
    nextSlide: "FoodCravings",
    typo1: "How would you describe your typical daily diet?",
    progress: 50,
  },
  foodCravings: {
    prev: "DailyDiet",
    current: "FoodCravings",
    nextSlide: "SugaryFoodConsuption",
    typo1: "Do you experience any specific food cravings?",
    progress: 60,
  },
  sugaryFoodConsuption: {
    prev: "FoodCravings",
    current: "SugaryFoodConsuption",
    typo1: "How often do you consume sugary foods and beverages?",
    nextSlide: "DietaryPattern",
    progress: 70,
  },
  dietaryPattern: {
    prev: "SugaryFoodConsuption",
    current: "DietaryPattern",
    nextSlide: "PhysicalActivity",
    typo1: "Are you following any specific dietary patterns?",
    progress: 85,
  },
  physicalActivity: {
    prev: "DietaryPattern",
    current: "PhysicalActivity",
    nextSlide: "StressLevel",
    typo1: "How often do you engage in moderate to vigorous physical activity?",
    progress: 90,
  },
  stressLevel: {
    prev: "PhysicalActivity",
    current: "StressLevel",
    nextSlide: "ReceiveResult",
    typo1: "How would you describe your usual stress levels?",
    progress: 95,
  },
};

export default data;
