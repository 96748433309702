import React, { useContext, useEffect } from "react";
import GtnInputs from "../Common/ButtonInput/GtnInputs";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";

const GetToKnow = () => {
  const { formValues, onChange } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { age, occupation, height, body_weight } = formValues;
  let gtnInputs = [
    {
      label: "Years",
      type: "number",
      content: "Enter your Age...",
      img: "/assets/icons/Years.svg",
      name: "age",
      value: age,
    },
    {
      label: "KG",
      type: "number",
      content: "Weight...",
      img: "/assets/icons/KG.svg",
      name: "body_weight",
      value: body_weight,
    },
    {
      label: "Feet",
      type: "number",
      content: "Height...",
      img: "/assets/icons/Feet.svg",
      name: "height",
      value: height,
    },
    {
      label: "",
      type: "dropdown",
      content: "Height...",
      img: "/assets/icons/occupation.png",
      name: "occupation",
      value: occupation,
    },
  ];
  useEffect(() => {
    if (age && occupation && height && body_weight) {
      activeNavigation();
    } else {
      inactiveNavigation();
    }
  }, [age, occupation, height, body_weight]);

  const renderGtnInputs = gtnInputs.map(
    ({ label, type, content, img, name, value }, index) => (
      <GtnInputs
        key={index}
        type={type}
        label={label}
        content={content}
        img={img}
        name={name}
        value={value}
        onChange={onChange}
      />
    )
  );
  return (
    <div className="gtn-inputs-main justified flex_column">
      {renderGtnInputs}
    </div>
  );
};

export default GetToKnow;
