import React, { useContext } from "react";
import SectionSlider from "./SectionSlider";
import Home from "./Home/Home";
import ShowComponent from "./Common/ShowComponent/ShowComponent";
import { slideHandleContext } from "../Context/SlideHandler";
const Index = () => {
  const { section, setSection } = useContext(slideHandleContext);
  const changeSection = (e) => {
    const { name } = e.target;
    setSection(name);
  };
  return (
    <div className="index-main">
      <div className="index-inner">
        <div className="index-content vhHeight">
          <ShowComponent condition={section === "home"}>
            <div className="render-animation">
              <Home onClick={changeSection} />
            </div>
          </ShowComponent>
          <ShowComponent condition={section === "form"}>
            <div className="render-animation">
              <SectionSlider />
            </div>
          </ShowComponent>
        </div>
      </div>
    </div>
  );
};

export default Index;
