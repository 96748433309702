import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const FoodCravings = () => {
  const foodCravings = [
    {
      type: "No, not at all",
      link: "/assets/logos/pcos_form/food_cravings/Craving_2.svg",
      linkChecked:
        "/assets/logos/pcos_form/food_cravings/Craving_2_checked.svg",
      typo: "",
    },
    {
      type: "Yes, but I am able to suppress it",
      link: "/assets/logos/pcos_form/food_cravings/Craving_3.svg",
      linkChecked:
        "/assets/logos/pcos_form/food_cravings/Craving_3_checked.svg",
      typo: "",
    },
   
    
    {
      type: "Yes, and I fall for it",
      link: "/assets/logos/pcos_form/food_cravings/Craving_1.svg",
      linkChecked:
        "/assets/logos/pcos_form/food_cravings/Craving_1_checked.svg",
      typo: "",
    },
    
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { food_cravings } = formValues;

  useEffect(() => {
    if (food_cravings === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [food_cravings]);
  return (
    <CardRenderer
      data={foodCravings}
      value={food_cravings}
      onClick={onClick}
      name="food_cravings"
    />
  );
};

export default FoodCravings;
