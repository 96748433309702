import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const BodySymptoms = () => {
  const bodySymptoms = [
    {
      type: "none",
      link: "/assets/logos/pcos_form/skin/Skin_4.svg",
      linkChecked: "/assets/logos/pcos_form/skin/Skin_4_checked.svg",
      typo: "",
    },
    {
      type: "I notice dark and thick hair growth on various parts of my body",
      link: "/assets/logos/pcos_form/skin/Skin_1.svg",
      linkChecked: "/assets/logos/pcos_form/skin/Skin_1_checked.svg",
      typo: "",
    },
    {
      type: "I notice acne on various parts of my body (Face, back)",
      link: "/assets/logos/pcos_form/skin/Skin_2.svg",
      linkChecked: "/assets/logos/pcos_form/skin/Skin_2_checked.svg",
      typo: "",
    },
    {
      type: "I notice oil on my skin",
      link: "/assets/logos/pcos_form/skin/Skin_3.svg",
      linkChecked: "/assets/logos/pcos_form/skin/Skin_3_checked.svg",
      typo: "",
    },
  
  ];

  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { bodypart_notice } = formValues;

  useEffect(() => {
    if (!bodypart_notice.length) {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [bodypart_notice.length]);
  return (
    <CardRenderer
      data={bodySymptoms}
      value={bodypart_notice}
      onClick={onClick}
      name="bodypart_notice"
      addClass="multipleSelection"
      mToS={true}
    />
  );
};

export default BodySymptoms;
