import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const StressLevel = () => {
  const stress_level_data = [
    {
      type: "Level 1",
      link: "/assets/logos/pcos_form/stress_level/Mood_1.svg",
      linkChecked: "/assets/logos/pcos_form/stress_level/Mood_1_checked.svg",
      typo: "",
    },
    {
      type: "Level 2",
      link: "/assets/logos/pcos_form/stress_level/Mood_5.svg",
      linkChecked: "/assets/logos/pcos_form/stress_level/Mood_5_checked.svg",
      typo: "",
    },
    {
      type: "Level 3",
      link: "/assets/logos/pcos_form/stress_level/Mood_4.svg",
      linkChecked: "/assets/logos/pcos_form/stress_level/Mood_4_checked.svg",
      typo: "",
    },
    {
      type: "Level 4",
      link: "/assets/logos/pcos_form/stress_level/Mood_3.svg",
      linkChecked: "/assets/logos/pcos_form/stress_level/Mood_3_checked.svg",
     
      typo: "",
    },
    {
      type: "Level 5",

      link: "/assets/logos/pcos_form/stress_level/Mood_2.svg",
      linkChecked: "/assets/logos/pcos_form/stress_level/Mood_2_checked.svg",
      
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { stress_level } = formValues;

  useEffect(() => {
    if (stress_level === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [stress_level]);
  return (
    <CardRenderer
      data={stress_level_data}
      value={stress_level}
      onClick={onClick}
      name="stress_level"
    />
  );
};

export default StressLevel;
