import { useState, createContext } from "react";

const slideHandleContext = createContext();

const SlideHandlerProvider = ({ children }) => {
  const [section, setSection] = useState("home");
  const [prevSlide, setPrevSlide] = useState("");
  const [currentSlide, setCurrentSlide] = useState("GetToKnow");
  const [nextSlide, setNextSlide] = useState("");
  const [navigation, setNavigation] = useState(true);
  const [fieldEmpty, setFieldEmpty] = useState(false);

  const [progress, setProgress] = useState(90);
  const activeNavigation = () => {
    setNavigation(false);
  };
  const inactiveNavigation = () => {
    setNavigation(true);
  };
  const assignPrevSlide = (slide) => {
    setPrevSlide(slide);
  };
  const assignCurrentSlide = (slide) => {
    setFieldEmpty(false);
    setCurrentSlide(slide);
    if (window.document.body.clientWidth <= 950) {
      window.scrollTo(0, 0);
    }
  };
  const assignNextSlide = (nextSlide) => {
    setNextSlide(nextSlide);
  };
  const fieldSelectionError = () => {
    setFieldEmpty(true);

    setTimeout(() => {
      setFieldEmpty(false);
    }, 3000);
  };
  const freshStart = () => {
    setCurrentSlide("GetToKnow");
    setSection("home");
  };
  const updateProgress = (sectionProgress) => {
    setProgress(sectionProgress);
  };
  return (
    <slideHandleContext.Provider
      value={{
        currentSlide,
        nextSlide,
        prevSlide,
        assignPrevSlide,
        assignCurrentSlide,
        assignNextSlide,
        inactiveNavigation,
        activeNavigation,
        navigation,
        fieldEmpty,
        setNavigation,
        fieldSelectionError,
        section,
        setSection,
        freshStart,
        updateProgress,
        progress,
      }}
    >
      {children}
    </slideHandleContext.Provider>
  );
};

export { slideHandleContext, SlideHandlerProvider };
