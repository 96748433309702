import { useState, createContext } from "react";

const progressBarContext = createContext();

const ProgressContextProvider = ({ children }) => {
  const [progress, setProgress] = useState(50);

  const setProgressValue = (progress) => {
    setProgress(progress);
  };
  return (
    <progressBarContext.Provider
      value={{
        progress,
        setProgressValue,
      }}
    >
      {children}
    </progressBarContext.Provider>
  );
};

export { progressBarContext, ProgressContextProvider };
