import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
const OftenPeriods = () => {
  const periodsFrequency = [
    {
      type: "My periods are regular (Every 21-34 days)",
      link: "/assets/logos/pcos_form/calenders/regular.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/regular.svg",
      typo: "",
    },
    {
      type: "I get my periods every 20 days or less",
      link: "/assets/logos/pcos_form/calenders/frequent.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/frequent.svg",
      typo: "",
    },
    {
      type: "I get my periods every 35 days or longer",
      link: "/assets/logos/pcos_form/calenders/infrequent.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/infrequent.svg",
      typo: "",
    },
    {
      type: "I go months without a period",
      link: "/assets/logos/pcos_form/calenders/irregular.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/irregular.svg",
      typo: "",
    },
    {
      type: "I am on birth control so I don't know",
      link: "/assets/logos/pcos_form/calenders/unknown.svg",
      linkChecked: "/assets/logos/pcos_form/calenders/unknown.svg",
      typo: "",
    },
  ];
  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { periods_frequency } = formValues;

  useEffect(() => {
    if (periods_frequency === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [periods_frequency]);
  return (
    <>
      <CardRenderer
        data={periodsFrequency}
        value={periods_frequency}
        onClick={onClick}
        name="periods_frequency"
      />
    </>
  );
};

export default OftenPeriods;
