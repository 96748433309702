import React from "react";
import "./HeroSection.css";
import WonderWomenLogo from "../../../assets/logos/ww.svg";
import ShowComponent from "../ShowComponent/ShowComponent"
const HeroSection = ({ typo1, typo2, lightTypo, midTypo}) => {
  return (
    <div className="hero-section-main my_row flex_column justify_start align_center">
      <div className="hero-section-img-container width justified">
        <img
          className="hero-section-image"
          src={WonderWomenLogo}
          alt="wonder-women"
        />
      </div>


    
   
    </div>
  );
};

export default HeroSection;
