import React, { useContext, useRef, useEffect } from "react";
import "./ResultChart.css";
import Button from "../Common/Button/Button";
import GaugeChart from "react-gauge-chart";

import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";
import ShowComponent from "../Common/ShowComponent/ShowComponent";
import Chart from "./Chart/Chart";

import RangeTypo from "./RangeTypo/RangeTypo";
const ResultChart = () => {
  const { results, resetForm } = useContext(formValuesContext);
  const { freshStart } = useContext(slideHandleContext);

  const restart = () => {
    freshStart();
    resetForm();
  };
  const GetPCOSCarePlan = () => {
    window.location.href = 'https://www.wonderwomen.pk/pcos-reverse-with-wonder-women';
  };

  const calendarButtonRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://calendar.google.com/calendar/scheduling-button-script.js"; // Replace with the URL to the script that defines the `calendar` object
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      const target = calendarButtonRef.current;
      if (target) {
        window.calendar.schedulingButton.load({
          //url: "https://health.wonderwomen.pk/consultation",
          url: "https://calendar.google.com/calendar/appointments/schedules/AcZssZ2wYBeRvHU2tvmkmS2FPwGQEQozZOmBJW7vmDYFGQr0mr4m6jyXIY85mvzH9Q0iHc1CZ-IRBaqR?gv=true",
          color: "#D20073",
          fontWeight: "Bold",
          fontSize: "22px",
          label: "Book Free Consultation",

          target,
        });
      }
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        style={{
          gap: "30px",
          marginTop: "50px",
          marginBottom: "60px",
        }}
        className="close-test-again-button width justified"
      >
        <div className="refButton" ref={calendarButtonRef}></div>
        <Button
          variant="filled"
          border={true}
          text="Get Your PCOS Care Plan"
          onClick={GetPCOSCarePlan}
        />


      </div>

      <div className="meters-container justified">
        <Chart
          typo="Your Clinical Risk Score is"
          score={results.section1_sum}
        >
          <ShowComponent condition={results.section1_sum > 0 && results.section1_sum <= 30}>
            <RangeTypo
              variant="green"
              range="0-30"
              typo="Looks like you're in the clear for now, but regular monitoring is recommended. If you are still bothered by any symptom, no harm in chatting with an expert for some peace of mind."
              indicator={true}
            />
          </ShowComponent>
          <ShowComponent condition={results.section1_sum > 30 && results.section1_sum <= 60}>
            <RangeTypo
              variant="orange"
              range="30-60"
              typo="Your score's giving a little heads-up about the possibility of PCOS. Timely diagnose and treatment is the key to PCOS reversal. Scheule a free consult with our PCOS expert."
              indicator={true}

            />
          </ShowComponent>
          <ShowComponent condition={results.section1_sum > 60 && results.section1_sum <= 100}>
            <RangeTypo
              variant="red"
              range="60-100"
              typo="Consult ASAP!! The score's waving a bit of a red flag."
              indicator={true}
            />
          </ShowComponent>
        </Chart>

        <ShowComponent condition={parseFloat(results.section2_sum) !== 0}>
          <Chart
            typo="Your Lifestyle Risk Score is"
            score={results.section2_sum}
          />
        </ShowComponent>
      </div>
      <div className="score-guide">
        <p className="score-type-heading">Score Guide</p>
        <RangeTypo
          variant="green"
          range="0-30"
          typo="Looks like you're in the clear for now, but regular monitoring is recommended. If you are still bothered by any symptom, no harm in chatting with an expert for some peace of mind."
        />
        <RangeTypo
          variant="yellow"
          range="30-60"
          typo="Your score's giving a little heads-up about the possibility of PCOS. Timely diagnose and treatment is the key to PCOS reversal. Scheule a free consult with our PCOS expert."
        />
        <RangeTypo
          variant="red"
          range="60-100"
          typo="Consult ASAP!! The score's waving a bit of a red flag."
        />
      </div>
      <div className="disclaimer-main">
        <div className="disclaimer-inner">
          <div className="disclaimer-heading">
            <span className="disclaimer-heading">Disclaimer</span>
          </div>
          <div className="disclaimer-typo">
            <span className="disclaimer-typo">
              Our Assessment tool is developed by PCOS experts in accordance
              with international standards, evidence-based criteria, and
              clinical best practices used to diagnose PCOS. Remember, this
              assessment is a valuable tool designed to provide you with initial
              insights into your PCOS risk. It is essential to consult with a
              healthcare professional for a comprehensive evaluation and
              personalized guidance.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultChart;
