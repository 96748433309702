import React from "react";
import "./Home.css";
import logo from "./ww.svg";
import featuredImg from "./img.png";
const Home = ({ onClick }) => {
  return (
    <div className="container">
      <div className="navbar">
        <img src={logo} alt="logo" className="logo" />
      </div>

      <div className="content">
        <h1>
          Test your
          <br />
          PCOS risk score
        </h1>
        <p>MAKING WOMEN HEALTH ACCESSIBLE.</p>

        <div className="arrow-icons">
          <button id="Start" className="btn" name="form" onClick={onClick}>
            Start
          </button>
        </div>

        <img src={featuredImg} className="featured-image" />
      </div>
    </div>
  );
};

export default Home;
