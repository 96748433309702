import React from "react";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";

const BodyHairRenderer = ({ name, buttons, value, buttonName, onClick }) => {
  return (
    <div
      style={{
        marginBottom: "10px",
      }}
      className="hair-renderer-main"
    >
      <div
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          color: "#D42588",
          font: "normal normal bold 18px Montserrat",
          letterSpacing: "1.26px",
        }}
        className="hair-renderer-inner"
      >
        <h3
          style={{
            marginBottom: "3px",
            fontSize: "18px"
          }}
        >
          {name}
        </h3>
        <div
          style={{
            gap: "10px",
          }}
          className="hair-renderer-content justified"
        >
          {buttons.map(({ content, content2 }, index) => (
            <button
              key={index}
              style={{
                width: "63px",
                height: "63px",
                backgroundImage: `${
                  content !== "No Hair"
                    ? `url(${index === value ? content2 : content}`
                    : "transparent"
                }`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundColor: index === value ? "#0f138c" : "transparent",
                color: index === value ? "#fff" : "#0f138c",
              }}
              id={index}
              name={buttonName}
              onClick={onClick}
            >
              <ShowComponent condition={content === "No Hair"}>
                {content}
              </ShowComponent>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BodyHairRenderer;
