import React, { useEffect, useContext } from "react";
import CardRenderer from "../Common/CardRenderer/CardRenderer";
import { formValuesContext } from "../../Context/ValuesContext";
import { slideHandleContext } from "../../Context/SlideHandler";

const SleepStatus = () => {
  const sleepStatus = [
    {
      type: "Comfortable Sleep (8 hr a day)",
      link: "/assets/logos/pcos_form/sleep/Sleep_1.svg",
      linkChecked: "/assets/logos/pcos_form/sleep/Sleep_1_checked.svg",
      typo: "",
    },
    {
      type: "Restless",
      link: "/assets/logos/pcos_form/sleep/Sleep_2.svg",
      linkChecked: "/assets/logos/pcos_form/sleep/Sleep_2_checked.svg",
      typo: "",
    },
    {
      type: "Wake up tired",
      link: "/assets/logos/pcos_form/sleep/Sleep_3.svg",
      linkChecked: "/assets/logos/pcos_form/sleep/Sleep_3_checked.svg",
      typo: "",
    },
    {
      type: "Sleep throughout the day",
      link: "/assets/logos/pcos_form/sleep/Sleep_4.svg",
      linkChecked: "/assets/logos/pcos_form/sleep/Sleep_4_checked.svg",
      typo: "",
    },
  ];

  const { formValues, onClick } = useContext(formValuesContext);
  const { activeNavigation, inactiveNavigation } =
    useContext(slideHandleContext);

  const { sleep } = formValues;

  useEffect(() => {
    if (sleep === "") {
      inactiveNavigation();
    } else {
      activeNavigation();
    }
  }, [sleep]);
  return (
    <CardRenderer
      data={sleepStatus}
      value={sleep}
      onClick={onClick}
      name="sleep"
    />
  );
};

export default SleepStatus;
