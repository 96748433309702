import React from "react";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";

const RangeTypo = ({ variant, range, typo, indicator }) => {

  return (
    <div className="score">
      <ShowComponent condition={!indicator}>
        <div className="score-left">
          <span className={`score-range ${variant}`}>{range}</span>
        </div>
      </ShowComponent>
      <div className="score-right">
        <p style={{
          color: indicator && variant,
        }} className={`score-description`}>{typo}</p>
      </div>
    </div>
  );
};

export default RangeTypo;
